import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { meetingService } from "~/core/services";

export const useModules = () => {
  return useQuery({
    queryKey: ["modules"],
    queryFn: () =>
      meetingService
        .getInstance()
        .getModulesApi()
        .meetingModulesList(),
  });
};

export const useModuleToggle = () => {
  const meetingId = useMeetingId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (options: { id: string }) =>
      meetingService
        .getInstance()
        .getModulesApi().meetingsModulesToggleCreate({
          meetingUuid: meetingId.value!,
          moduleUuid: options.id,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["meetings"],
      });
    },
  });
}

export const useModule = () => {
  const store = useUserStore();
  const { hasModule } = storeToRefs(store);

  return hasModule
}
